<template>
	<div>
		<div class="banner" :style="'background-image:' + `url(${require('@/assets/img/product-banner.jpg')})`">
			<div class="ba_titleDiv">
				<span>{{$t('factoryDisplay.productList.title')}}<span style="color: #d1102d;">{{$t('factoryDisplay.productList.text')}}</span></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 200px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 200px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 140px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner{
			margin-top: 60px;
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 21px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner{
			margin-top: 60px;
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_titleDiv{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
		}
	}
	
</style>