<template>
	<div>
		<div class="productList">
			<div class="pl_contentDiv">
				<div class="pl_cd_div" v-for="(item,index) in $t('factoryDisplay.productList.list')" :key="index">
					<div class="pl_cd_di_imgDiv">
						<img class="pl_cd_di_id_img" :src="item.img" />
					</div>
					<div class="pl_cd_id_contentDiv">
						<div class="pl_cd_id_cd_title">{{item.title}}</div>
						<div class="pl_cd_id_cd_text">{{item.text}}</div>
					</div>
					<div class="pl_cd_id_bottomDiv">
						<div class="pl_cd_id_bd_likeDiv">
							<img class="pl_cd_id_bd_ld_img" src="../../../../assets/img/icon/praise.png" />
							<span class="pl_cd_id_bd_ld_text">0</span>
						</div>
						<div class="pl_cd_id_bd_likeDiv">
							<img class="pl_cd_id_bd_ld_img" src="../../../../assets/img/icon/view.png" />
							<span class="pl_cd_id_bd_ld_text">1200</span>
						</div>
						<div class="pl_cd_id_bd_likeDiv" style="text-align: right;">
							<img class="pl_cd_id_bd_ld_img" src="../../../../assets/img/icon/record.png" />
							<span class="pl_cd_id_bd_ld_text">{{item.time}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				list:[{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr1.jpg',
					title:"多层盲孔高频PCB板",
					text:"盲孔高频电路板",
					time:"1个月前"
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr2.jpg',
					title:"微波射频板",
					text:"4层沉金高频板",
					time:"1个月前"
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr3.jpg',
					title:"四层混压高频板",
					text:"Rogers高频板",
					time:"1个月前"
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr4.jpg',
					title:"6层高频PCB",
					text:"6层沉金线路板",
					time:"1个月前"
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr5.jpg',
					title:"汽车摄像头PCB",
					text:"刚挠结合电路板",
					time:"1个月前"
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr6.jpg',
					title:"通讯4层PCB板",
					text:"无铅喷锡电路板",
					time:"1个月前"
				},
				{
					img:process.env.VUE_APP_OSS_URL + '/factoryDisplay/pr7.jpg',
					title:"汽车4层PCB板",
					text:"四层沉金线路板",
					time:"1个月前"
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.productList{
			padding: 40px 200px;
			
			.pl_contentDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pl_cd_div{
					width: 19%;
					margin-right: 1%;
					margin-bottom: 2%;
					background: #fff;
					border: 1px solid #e6e6e6;
					position: relative;
					
					.pl_cd_di_imgDiv{
						height: 156px;
						overflow: hidden;
						position: relative;
						text-align: center;
						
						.pl_cd_di_id_img{
							
						}
					}
					
					.pl_cd_id_contentDiv{
						padding: 20px;
						border-bottom: 1px solid #e6e6e6;
						
						.pl_cd_id_cd_title{
							font-size: 16px;
						}
						
						.pl_cd_id_cd_text{
							font-size: 12px;
							color: #999;
							padding-top: 10px;
						}
					}
					
					.pl_cd_id_bottomDiv{
						padding: 10px 20px;
						display: flex;
						
						.pl_cd_id_bd_likeDiv{
							display: flex;
							width: 33%;
							align-items: center;
							
							.pl_cd_id_bd_ld_img{
								width: 16px;
								height: 16px;
							}
							
							.pl_cd_id_bd_ld_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.productList{
			padding: 40px 200px;
			
			.pl_contentDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pl_cd_div{
					width: 19%;
					margin-right: 1%;
					margin-bottom: 2%;
					background: #fff;
					border: 1px solid #e6e6e6;
					position: relative;
					
					.pl_cd_di_imgDiv{
						height: 156px;
						overflow: hidden;
						position: relative;
						text-align: center;
						
						.pl_cd_di_id_img{
							
						}
					}
					
					.pl_cd_id_contentDiv{
						padding: 20px;
						border-bottom: 1px solid #e6e6e6;
						
						.pl_cd_id_cd_title{
							font-size: 16px;
						}
						
						.pl_cd_id_cd_text{
							font-size: 12px;
							color: #999;
							padding-top: 10px;
						}
					}
					
					.pl_cd_id_bottomDiv{
						padding: 10px 20px;
						display: flex;
						
						.pl_cd_id_bd_likeDiv{
							display: flex;
							width: 33%;
							align-items: center;
							
							.pl_cd_id_bd_ld_img{
								width: 16px;
								height: 16px;
							}
							
							.pl_cd_id_bd_ld_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.productList{
			padding: 40px 140px;
			
			.pl_contentDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pl_cd_div{
					width: 19%;
					margin-right: 1%;
					margin-bottom: 2%;
					background: #fff;
					border: 1px solid #e6e6e6;
					position: relative;
					
					.pl_cd_di_imgDiv{
						height: 156px;
						overflow: hidden;
						position: relative;
						text-align: center;
						
						.pl_cd_di_id_img{
							
						}
					}
					
					.pl_cd_id_contentDiv{
						padding: 20px;
						border-bottom: 1px solid #e6e6e6;
						
						.pl_cd_id_cd_title{
							font-size: 16px;
						}
						
						.pl_cd_id_cd_text{
							font-size: 12px;
							color: #999;
							padding-top: 10px;
						}
					}
					
					.pl_cd_id_bottomDiv{
						padding: 10px 20px;
						display: flex;
						
						.pl_cd_id_bd_likeDiv{
							display: flex;
							width: 33%;
							align-items: center;
							
							.pl_cd_id_bd_ld_img{
								width: 16px;
								height: 16px;
							}
							
							.pl_cd_id_bd_ld_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.productList{
			padding: 40px 30px;
			
			.pl_contentDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pl_cd_div{
					width: 19%;
					margin-right: 1%;
					margin-bottom: 2%;
					background: #fff;
					border: 1px solid #e6e6e6;
					position: relative;
					
					.pl_cd_di_imgDiv{
						height: 156px;
						overflow: hidden;
						position: relative;
						text-align: center;
						
						.pl_cd_di_id_img{
							
						}
					}
					
					.pl_cd_id_contentDiv{
						padding: 20px;
						border-bottom: 1px solid #e6e6e6;
						
						.pl_cd_id_cd_title{
							font-size: 16px;
						}
						
						.pl_cd_id_cd_text{
							font-size: 12px;
							color: #999;
							padding-top: 10px;
						}
					}
					
					.pl_cd_id_bottomDiv{
						padding: 10px 20px;
						display: flex;
						
						.pl_cd_id_bd_likeDiv{
							display: flex;
							width: 33%;
							align-items: center;
							
							.pl_cd_id_bd_ld_img{
								width: 16px;
								height: 16px;
							}
							
							.pl_cd_id_bd_ld_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.productList{
			padding: 40px 30px;
			
			.pl_contentDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pl_cd_div{
					width: 49%;
					margin-right: 1%;
					margin-bottom: 2%;
					background: #fff;
					border: 1px solid #e6e6e6;
					position: relative;
					
					.pl_cd_di_imgDiv{
						height: 156px;
						overflow: hidden;
						position: relative;
						text-align: center;
						
						.pl_cd_di_id_img{
							
						}
					}
					
					.pl_cd_id_contentDiv{
						padding: 20px;
						border-bottom: 1px solid #e6e6e6;
						
						.pl_cd_id_cd_title{
							font-size: 16px;
						}
						
						.pl_cd_id_cd_text{
							font-size: 12px;
							color: #999;
							padding-top: 10px;
						}
					}
					
					.pl_cd_id_bottomDiv{
						padding: 10px 20px;
						display: flex;
						
						.pl_cd_id_bd_likeDiv{
							display: flex;
							width: 33%;
							align-items: center;
							
							.pl_cd_id_bd_ld_img{
								width: 16px;
								height: 16px;
							}
							
							.pl_cd_id_bd_ld_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.productList{
			padding: 40px 30px;
			
			.pl_contentDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				
				.pl_cd_div{
					width: 100%;
					margin-bottom: 2%;
					background: #fff;
					border: 1px solid #e6e6e6;
					position: relative;
					
					.pl_cd_di_imgDiv{
						height: 156px;
						overflow: hidden;
						position: relative;
						text-align: center;
						
						.pl_cd_di_id_img{
							
						}
					}
					
					.pl_cd_id_contentDiv{
						padding: 20px;
						border-bottom: 1px solid #e6e6e6;
						
						.pl_cd_id_cd_title{
							font-size: 16px;
						}
						
						.pl_cd_id_cd_text{
							font-size: 12px;
							color: #999;
							padding-top: 10px;
						}
					}
					
					.pl_cd_id_bottomDiv{
						padding: 10px 20px;
						display: flex;
						
						.pl_cd_id_bd_likeDiv{
							display: flex;
							width: 33%;
							align-items: center;
							
							.pl_cd_id_bd_ld_img{
								width: 16px;
								height: 16px;
							}
							
							.pl_cd_id_bd_ld_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
					}
				}
			}
		}
	}
	
</style>